import {
    calibrationType,
    calibrationValues, FieldRowType, FieldsTypeTask,
    SensorUnit,
    TaskDataType,
    unit
} from "@/components/tracker/model/interface";
import {getObjectFromLocalStorage, removeObjectFromArrayInLocalStorage} from "@/components/tracker/model/storage";
import {
    CALIBRATION_KEY,
    calibrationFindValue, calibrationFindValueMultiple,
    TIME_CLEAR_CALIBRATION
} from "@/components/tracker/model/const";
import {
    checkArrayType,
    returnTypeCheckArray
} from "@/components/tracker/model/helpers";
import {renderLineChart} from "@/components/tracker/model/calibration/chart";
import {validateAllCalibration} from "@/components/tracker/model/calibration/validate";
import {quickSortSensorUnit} from "@/components/tracker/model/calibration/quickSort";
import {CatalogInteraction} from "@/components/tracker/model/methods";


export function computeCalibration(api: any, task: TaskDataType, updateStatus = true) {
    console.log('get calibration info')

    if (!shouldProcessCalibration(api.unitsData.inGsAndInTable)) {
        return;
    }

    (api.unitsData.inGsAndInTable as unknown as unit[]).forEach((currentUnit) => {

        if (currentUnit.many) {
            let dataGetFromTable = false
            let isExist = handleManyParams(currentUnit)
            let dataFromTableCalibration = null

            if (!isExist) {
                dataFromTableCalibration = isExitCalibrationFromTableData(api, task, currentUnit);
                dataGetFromTable = true
            }

            // Проверка на 2ДУТ
            currentUnit.calibrationCount = getFuelCount(currentUnit)


            if (dataFromTableCalibration && !dataFromTableCalibration.isMultiFuel) {
                // Если данные о ДУТ пришли из таблицы
                if (dataGetFromTable && currentUnit.calibrationCount !== 1) {
                    currentUnit.calibrationCount = 1
                }
            }


            if (dataFromTableCalibration && dataFromTableCalibration.isExitTableValue || isExist) {
                setCalibrationToDefaultValue(currentUnit, updateStatus);
                insertCalibrationValueFromLsOfBack(currentUnit, api, task)

                return
            }
        }

        insertCalibrationValueFromLsOfBack(currentUnit, api, task)

    });

    (api.unitsData.inGsAndInTable as unknown as string) = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable as unknown as string));
}


export function updateCalibrationBySocket(api: any, task: TaskDataType) {
    console.log('!!!!!!!!update calibration by socket')

    computeCalibration(api, task, false)
}

function shouldProcessCalibration(inGsAndInTable: unit[]) {
    return inGsAndInTable && inGsAndInTable.length > 0;
}

function handleManyParams(currentUnit: unit): boolean {
    if (!currentUnit.many || !currentUnit.many.hasOwnProperty('params') || currentUnit.many.params === null) return false

    let isExist = false

    Object.keys(currentUnit.many.params).forEach((paramsKey) => {
        if (calibrationFindValue.includes(paramsKey)) {
            isExist = true
        }
    });

    return isExist
}

export const isExitCalibrationFromTableData = (api: any, task: TaskDataType, currentUnit: unit): {isExitTableValue: boolean ,isMultiFuel: boolean} => {
    const fields = CatalogInteraction.getAllFields(task.fields)
    let isExitTableValue = false;
    let isMultiFuel = false

    // Проверка для МР и для СР
    if (api.formMR.includes(task.form_name)) {
        console.log('МР форма')
        const currentTable = fields.find(field => field.name === "Результат работ" && field.type === 'table') as FieldsTypeTask;
        (currentTable.values as FieldRowType[])?.forEach(row => {

            const IMEICell = row.cells.find(cell => {
                if (
                    cell.name === "IMEI" && Number(currentUnit.tracker.tracker_imei) === Number(cell.value) ||
                    cell.name === "Номер блока" && Number(currentUnit.tracker.tracker_serial) === Number(cell.value)
                ) {
                    return cell
                }
            }) || null;

            if (IMEICell) {
                const additionalEquipment = row.cells.find(cell => cell.name === 'Оборудование');

                if (additionalEquipment && additionalEquipment.value && (additionalEquipment.value as string[]).length > 0) {
                    isExitTableValue = (additionalEquipment.value as string[]).some(val => {
                        if (calibrationFindValue.includes(val)) {
                            return true
                        }

                        if ( calibrationFindValueMultiple.includes(val)) {
                            isMultiFuel = true
                            return true
                        }
                    })
                }
            }
        })
    }

    if (api.formSR.includes(task.form_name)) {
        console.log('СР форма')
        const additionalEquipment = CatalogInteraction.getSensorInCPTable(api)

        if (additionalEquipment) {
            isExitTableValue = calibrationFindValue.includes(additionalEquipment.value as string) || calibrationFindValueMultiple.includes(additionalEquipment.value as string)
        }
    }


    return {isExitTableValue, isMultiFuel}
}


function insertCalibrationValueFromLsOfBack(currentUnit: unit, api: any, task: TaskDataType) {
    if (!currentUnit.calibrationArr) {
        return;
    }

    if (currentUnit.calibrationCount === 0 && currentUnit.calibrationArr) {
        currentUnit.calibrationCount = currentUnit.calibrationArr.length
    }

    // Смотрит на количество датчиков с тарировкой
    for (let i = 0; i < currentUnit.calibrationCount; i++) {
        const sensorWithCalibration = getSensorWithCalibration(currentUnit);

        // Получает объекты из ls, которые есть в этой задаче
        const calibrationDataFromLocalStorage = getObjectFromLocalStorage(CALIBRATION_KEY, task.id, currentUnit.unit.unit_id.toString(), i)

        const calibrationUnit: calibrationType = JSON.parse(JSON.stringify(currentUnit.calibrationArr[i]))

        if (calibrationDataFromLocalStorage && calibrationUnit.calibrationInfo && calibrationUnit.calibrationInfo.calibration) {
            // Если время с последнего изменения больше TIME_CLEAR_CALIBRATION то удаляю запись
            if (
                calibrationDataFromLocalStorage.created_at &&
                isExpired(calibrationDataFromLocalStorage.created_at, TIME_CLEAR_CALIBRATION)
            ) {
                removeObjectFromArrayInLocalStorage(CALIBRATION_KEY, calibrationDataFromLocalStorage.id, calibrationDataFromLocalStorage.calibrationIndex)
                return
            }

            calibrationUnit.calibrationInfo.calibrationValue = calibrationDataFromLocalStorage.value as calibrationValues[]
            // Строит график и заполняет остальные поля, если они есть
            renderLineChart(calibrationUnit)

            calibrationUnit.calibrationInfo.calibrationChartShow = true
            calibrationUnit.calibrationInfo.calibrationInput.calibrationSerialNumber = calibrationDataFromLocalStorage.fuel_serial ?? null
            calibrationUnit.calibrationInfo.calibrationInput.calibrationVolumeTank = calibrationDataFromLocalStorage.tank_volume ?? null
            calibrationUnit.calibrationInfo.calibrationInput.calibrationFilter = calibrationDataFromLocalStorage.filter ?? ''

            validateCalibrationFromSensorAndMount(calibrationUnit, sensorWithCalibration, FunctionTaskEnum.VALIDATE, i)

            currentUnit.calibrationArr[i] = calibrationUnit

        } else {
            // Если в ls ничего нет, но приходит калибровка с бэка
            const filledCalibration = validateCalibrationFromSensorAndMount(calibrationUnit, sensorWithCalibration, FunctionTaskEnum.INSERT, i)

            if (filledCalibration) {
                currentUnit.calibrationArr[i] = filledCalibration

                if (!calibrationUnit || !calibrationUnit.calibrationInfo) return;

                calibrationUnit.calibrationInfo.calibrationValue = calibrationUnit.calibration as calibrationValues[]

                if (calibrationUnit && calibrationUnit.calibrationInfo && calibrationUnit.calibrationInfo.calibrationValue.length === 0) {
                    calibrationUnit.calibrationInfo.calibrationValue.push([])
                }

                // Проверка, если последний элементам массива уже пусто, то не добавлять новый
                if (calibrationUnit && calibrationUnit.calibrationInfo && calibrationUnit.calibrationInfo.calibrationValue && calibrationUnit.calibrationInfo.calibrationValue.length) {
                    if (calibrationUnit.calibrationInfo.calibrationValue[calibrationUnit.calibrationInfo.calibrationValue.length - 1].length === 2) {
                        calibrationUnit.calibrationInfo.calibrationValue.push([])
                    }
                }

                renderLineChart(calibrationUnit)

                calibrationUnit.calibrationInfo.calibrationChartShow = true

                currentUnit.calibrationArr[i] = calibrationUnit
            } else {
                console.log('empty')
            }
        }

        console.log('end')

        setTimeout(() => {
            if (calibrationUnit && calibrationUnit.calibrationInfo && calibrationUnit.calibrationInfo.calibrationValue) {
                validateAllCalibration(calibrationUnit.calibrationInfo.calibrationValue)
            }
        }, 2000)
    }

}


// UTILS

export function setCalibrationToDefaultValue(unit: unit, updateStatus = true, index?: number) {
    if (!updateStatus) return

    const calibrationInfo = {
        calibration: true,
        calibrationExistOnServer: false,
        calibrationChartShow: false,
        calibrationConfirmPopover: false,
        calibrationRewritePopover: false,
        calibrationConfirmClearPopover: false,
        calibrationConfirmPopoverError: '',
        calibrationSubmitLoading: false,
        calibrationValidationError: '',
        calibrationSwapPopover: false,
        calibrationSettingPopover: false,
        calibrationEditOnlyText: false,
        calibrationInput: {
            calibrationVolumeTank: null as unknown as number,
            calibrationSerialNumber: null as unknown as number,
            calibrationFilter: ''
        },
        calibrationSetting: {
            calibrationSettingStep: null,
        },
        calibrationValue: [[]]
    }


    if (typeof index !== undefined && typeof index === "number") {
        unit.calibrationArr[index].calibrationInfo = calibrationInfo
        unit.calibrationArr[index].calibration = []

        return
    }

    unit.activeCalibrationIndex = 0
    unit.calibrationArr = []
    unit.calibrationArr.length = 0

    // Добавление данных тарировки в массив
    for (let i = 0; i < unit.calibrationCount; i++) {
        (unit.calibrationArr as any).push({
            calibrationInfo,
            calibration: null
        })
    }
}


function isExpired(timestamp: number, duration: number) {
    const now = new Date();
    return now.getTime() - timestamp >= duration;
}


function getFuelCount(currentUnit: unit) {
    let count = 0;

    currentUnit.sensors.find((el: SensorUnit) => {
        if (el && el.name) {
            if (el.name.toLowerCase().includes('бак')) {
                count += 1
            }
        }
    })

    if (count === 0) {
        currentUnit.sensors.find((el: SensorUnit) => {

            if (el && el.name) {
                if (el.name.toLowerCase().includes('уровень топлива')) {
                    count = 1
                }
            }
        })
    }

    if (count > 1) {
        currentUnit.multiCalibration = true
    }

    return count
}


// Если находит датчик бак, то меньший будет дут_1, больший дут_2 и тд, то есть по возрастанию
export function getSensorWithCalibration(currentUnit: unit) {
    const SENSOR_NAME_BAK = 'бак';
    const SENSOR_NAME_FUEL_LEVEL = 'уровень топлива';
    const SENSOR_NAME_FUEL = 'топливо'

    const multiFuel: SensorUnit[] = currentUnit.sensors.filter(el =>
        el && el.name && el.name.toLowerCase().includes(SENSOR_NAME_BAK)
    );

    const commonFuel: SensorUnit[] = currentUnit.sensors.filter(el =>
        el && el.name && (el.name.toLowerCase().includes(SENSOR_NAME_FUEL_LEVEL) || el.name.toLowerCase().includes(SENSOR_NAME_FUEL))
    );

    // Если установлено 2 дут, то обычный дут удаляем
    if (multiFuel.length > 0) {
        commonFuel.length = 0

        return multiFuel
    }

    return commonFuel
}

enum FunctionTaskEnum {
    INSERT = 'insert_calibration',
    VALIDATE = 'validate_exist_on_server'
}


// Проверяет заполнена ли тарировка, которая пришла с бэка и устанавливает ее
function validateCalibrationFromSensorAndMount(
    currentUnit: calibrationType,
    sensorWithCalibration: SensorUnit[] | [] = [],
    type: FunctionTaskEnum,
    index: number
): calibrationType | null {

    if (sensorWithCalibration.length === 0) {
        return null
    }

    // Если 1 дут
    if (sensorWithCalibration.length === 1) {
        if (type === FunctionTaskEnum.INSERT) {
            currentUnit.calibration = sensorWithCalibration[0].calibration
        }

        // Проверяет пришла ли с сервера заполненная тарировка или пустая
        if (checkArrayType(sensorWithCalibration[0].calibration) === returnTypeCheckArray.DOUBLE_ARR) {
            currentUnit.calibrationInfo.calibrationExistOnServer = true
        }

        return currentUnit
    }


    // Если 2 дут и больше
    if (sensorWithCalibration.length >= 2) {
        // Отфильтровать sensorWithCalibration по возрастанию
        const sortedSenor = quickSortSensorUnit(sensorWithCalibration);

        if (type === FunctionTaskEnum.INSERT) {
            // Получить меньший бак и загрузить в первый currentUnit и так далее по возрастанию
            currentUnit.calibration = sortedSenor[index].calibration
        }

        if (checkArrayType(sensorWithCalibration[0].calibration) === returnTypeCheckArray.DOUBLE_ARR) {
            currentUnit.calibrationInfo.calibrationExistOnServer = true
        }

        return currentUnit
    }

    return null
}
import {calibrationInfo, calibrationValues, unit} from "@/components/tracker/model/interface";
import {extractNumbers, isTwoDimensionalArrayEmpty} from "@/components/tracker/model/helpers";
import {renderLineChart} from "@/components/tracker/model/calibration/chart";
import {saveCalibrationValueInLs} from "@/components/tracker/model/calibration/storage";
import {validateAllCalibration} from "@/components/tracker/model/calibration/validate";
import {parseGoogleFile} from "@/components/tracker/model/calibration/parseFile/parseGoogleFile";
import {parseMultiFuel} from "@/components/tracker/model/calibration/parseFile/parseMultiFuel";


export function selectFileCalibration(api: any, tracker_serial: number) {
    const item = api.$refs[`calibration_ref_${tracker_serial}`] as HTMLElement[]
    if (!item || item.length === 0) return

    item[0].click()
}


export async function changeFileCalibration(api: any, event: Event, calibration: calibrationValues[], unit: unit, index: number): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || inputElement.files.length === 0) return

    const file = inputElement.files[0];

    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    if (file) {
        try {
            const text = await readFileAsText(file);
            if (!text) return

            const isGoogleFile = text.includes('Информация по авто')
            const isMultiFuel = text.includes('Бак 2')

            if (isGoogleFile) {
                if (isMultiFuel) {

                    if (calibrationArr.length === 1) {
                        api.$store.dispatch('setErrorNotification', {
                            status: true,
                            message: "У объекта только 1 бак"
                        })

                        return
                    }

                    //Пробежаться по calibrationArr циклом и записывать данные туда, не смотря на activeCalibrationIndex
                    const multiCalibration = parseMultiFuel(text)

                    calibrationArr.forEach((calibration, index) => {
                        index += 1;
                        calibration.calibration = (multiCalibration as any)[`calibrationFile_${index}`]
                        calibration.calibrationInfo.calibrationValue = (multiCalibration as any)[`calibrationFile_${index}`]

                        calibration.calibrationInfo.calibrationInput.calibrationFilter = (multiCalibration.calibrationInfoFile as any)[`Фильтрация ${index}`]
                        calibration.calibrationInfo.calibrationInput.calibrationVolumeTank = (multiCalibration.calibrationInfoFile as any)[`Объем бака ${index}`]
                        calibration.calibrationInfo.calibrationInput.calibrationSerialNumber = (multiCalibration.calibrationInfoFile as any)[`Бак ${index}`]

                        saveCalibrationDefault(api, unit, calibration.calibrationInfo, file)
                    })

                    unit.calibrationConfirmFilePopover = true

                    return

                }

                const {calibrationFile, calibrationInfoFile} = parseGoogleFile(text)

                calibrationInfo.calibrationValue = calibrationFile
                calibrationInfo.calibrationInput.calibrationFilter = calibrationInfoFile["Фильтрация"] ? calibrationInfoFile["Фильтрация"] : ""
                calibrationInfo.calibrationInput.calibrationVolumeTank = calibrationInfoFile["Объем бака"] ? Number(calibrationInfoFile["Объем бака"]) : null
                calibrationInfo.calibrationInput.calibrationSerialNumber = calibrationInfoFile["SN ДУТа"] ? Number(calibrationInfoFile["SN ДУТа"]) : null

                calibrationInfo.calibrationConfirmPopover = true

                saveCalibrationDefault(api, unit, calibrationInfo, file)

                return
            }

            const rows = []
            // Паттерн для поиска чисел в строке
            const pattern = /\d+(\.\d+)?/g;
            const splitByNewLine = text
                .replace(/[ \t]+/g, ' ')
                .replace(/\s*\n/g, '\n')
                .replace(/[\n\r]+\s*/g, '\n')
                .split('\n')

            for (let i = 0; i < splitByNewLine.length; i++) {
                const matches = splitByNewLine[i].match(pattern);
                if (matches) {
                    const [first, second] = matches;
                    rows.push([Number(first), Number(second)]);
                }
            }

            if (isTwoDimensionalArrayEmpty(rows)) {
                calibrationInfo.calibrationValue = [[]];
                calibrationInfo.calibrationFileName = file.name

                saveCalibrationValueInLs(unit, calibrationInfo.calibrationValue, api.task);

                return;
            }

            if (rows[rows.length - 1].length === 1) {
                rows.pop();
            }

            calibrationInfo.calibrationValue = rows as unknown as calibrationValues[];

            saveCalibrationDefault(api, unit, calibrationInfo, file)

        } catch (err) {

            console.error('Ошибка при чтении файла', err);

        } finally {
            inputElement.value = '';

            setTimeout(() => {
                renderLineChart(calibrationArr[activeCalibrationIndex]);
                setTimeout(() => {
                    if (calibrationInfo.calibrationValue) {
                        validateAllCalibration(calibrationInfo.calibrationValue)
                    }

                    calibrationInfo.calibrationChartShow = true;
                    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable));
                }, 100);
            }, 200);

        }
    }
}

function readFileAsText(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target) {
                resolve(String((e.target as FileReader).result));
            } else {
                reject(new Error('Ошибка при чтении файла'));
            }
        };

        reader.readAsText(file);
    });
}

function saveCalibrationDefault(api: any, unit: unit, calibrationInfo: calibrationInfo, file: File) {
    calibrationInfo.calibrationFileName = file.name

    if (calibrationInfo.calibrationValue[calibrationInfo.calibrationValue.length - 1].length === 2) {
        calibrationInfo.calibrationValue.push([]);
    }

    saveCalibrationValueInLs(unit, calibrationInfo.calibrationValue, api.task);
}
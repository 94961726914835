import {calibrationValues} from "@/components/tracker/model/interface";

export function pasteCalibration(value: string): calibrationValues[] | void {
    if (!value) return

    // Убирает все двойное пробелы и превращает в массив
    const splitByNewLine = value
        .replace(/[ \t]+/g, ' ')
        .replace(/\s*\n/g, '\n')
        .replace(/[\n\r]+\s*/g, '\n')
        .split('\n')

    if (splitByNewLine.length === 0) return;

    const resultArray = [];

    // Паттерн для поиска чисел в строке
    const pattern = /\d+(\.\d+)?/g;

    for (let i = 0; i < splitByNewLine.length; i++) {
        const matches = splitByNewLine[i].match(pattern);
        if (matches) {
            resultArray.push(matches.map(Number));
        }
    }

    if (resultArray.length === 0) return

    return resultArray
}
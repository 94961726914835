<template>
    <div :class="active ? 'popover active' : 'popover'" @click="changeActiveStatus(!active)">
        <div
            @click.stop
            :class="[
        'popover__content',
        { 'active': active },
        { 'fit-content': fitContent }
    ]"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Popup",
    props: {
        active: {
            type: Boolean
        },
        fitContent: {
            type: Boolean
        }
    },
    emits: ['setActive'],
    // watch: {
    //     active: {
    //        handler(newValue) {
    //            newValue ?  document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
    //        },
    //        deep: true,
    //        immediate: true,
    //    }
    // },
    // beforeDestroy() {
    //     document.body.style.overflow = 'visible'
    // },
    methods: {
        changeActiveStatus(status) {
            this.$emit('setActive', status)
        }
    }
}
</script>

<style scoped>
.popover {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 100vw;
    height: 100%;

    transition: .5s;

    opacity: 0;
    z-index: 2000;

    pointer-events: none;

    background-color: rgba(0, 0, 0, 0.4);
}

.popover.active {
    opacity: 1;

    pointer-events: all;
}

.popover__content {
    width: 100vw;
    height: 75%;

    display: flex;
    flex-direction: column;

    padding: 24px 20px;

    transition: .5s;
    transform: scale(.9) translateY(600px);
    overflow-x: scroll;

    background-color: white;
    border-radius: 12px 12px 0 0;
}

.popover__content.active {
    transform: scale(1);
}

.popover__content.fit-content {
    height: fit-content;
}

@media screen and ( max-height: 670px ) {
    .popover__content {
        height: 85%;
    }
}

</style>
import {isTwoDimensionalArrayEmpty} from "@/components/tracker/model/helpers";
import {calibrationValues, unit} from "@/components/tracker/model/interface";

export function isColumnLayoutCorrect(unit: unit): boolean {
    const { calibrationArr, activeCalibrationIndex } = unit;

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return false

    const calibrationValue = calibrationArr[activeCalibrationIndex].calibrationInfo.calibrationValue as calibrationValues[]

    if (!calibrationValue || calibrationValue.length === 0) return false

    const inputColumn = calibrationValue[calibrationValue.length - 2][0]
    const outputColumn = calibrationValue[calibrationValue.length - 2][1]

    if (inputColumn < outputColumn) {
        calibrationArr[activeCalibrationIndex].calibrationInfo.calibrationSwapPopover = true
        return false
    }

    return true
}


export function validateFieldInColumn(calibration: calibrationValues[], fieldId: number, cellId: number) {
    // Проверяет что есть четкая последовательность по возрастанию, елис будет 1,4,65,7 то ячейка с 65 будет выделена
    const currentValue = calibration[fieldId][cellId]
    const valueBeforeChangedElement = calibration[fieldId - 1][cellId]
    const valueAfterChangedElement = calibration[fieldId + 1][cellId]

    const cell = document.querySelector(`.cell_${fieldId}_${cellId}`) as HTMLElement | null;

    if (cell) {
        const input = cell.querySelector("input") as HTMLElement | null;

        const shouldUnderline =
            (valueBeforeChangedElement !== undefined && currentValue < valueBeforeChangedElement) ||
            (valueAfterChangedElement !== undefined && currentValue > valueAfterChangedElement);

        if (input) {
            input.style.backgroundColor = shouldUnderline ? '#EC5555' : '#FFF';
            input.style.color = shouldUnderline ? '#FFF' : '#000';
        }

        cell.style.backgroundColor = shouldUnderline ? '#EC5555' : '#FFF';
        cell.style.color = shouldUnderline ? '#FFF' : '#000';

        validateFieldInColumn(calibration, fieldId - 1, cellId)
        validateFieldInColumn(calibration, fieldId + 1, cellId)
    }
}

export function validateAllCalibration(calibration: calibrationValues[]) {

    for (let fieldId = 0; fieldId < calibration.length; fieldId++) {
        for (let cellId = 0; cellId < calibration[fieldId].length; cellId++) {

            const cell = document.querySelector(`.cell_${fieldId + 1}_${cellId}`) as HTMLElement | null;

            if (cell) {
                cell.style.backgroundColor = '#FFF';
                cell.style.color = '#000';

                const input = cell.querySelector("input") as HTMLElement | null;

                if (input) {
                    input.style.backgroundColor = '#FFF';
                    input.style.color = '#000';
                }
            }
        }
    }

    for (let fieldId = 0; fieldId < calibration.length; fieldId++) {
        for (let cellId = 0; cellId < calibration[fieldId].length; cellId++) {
            const currentValue = calibration[fieldId][cellId];
            const valueBeforeChangedElement = calibration[fieldId - 1]?.[cellId];
            const valueAfterChangedElement = calibration[fieldId + 1]?.[cellId];

            const cell = document.querySelector(`.cell_${fieldId}_${cellId}`) as HTMLElement | null;

            if (cell) {
                const input = cell.querySelector("input") as HTMLElement | null;

                const shouldUnderline =
                    (valueBeforeChangedElement !== undefined && Number(currentValue) < Number(valueBeforeChangedElement)) ||
                    (valueAfterChangedElement !== undefined && Number(currentValue) > Number(valueAfterChangedElement));

                if (input) {
                    input.style.backgroundColor = shouldUnderline ? '#EC5555' : '#FFF';
                    input.style.color = shouldUnderline ? '#FFF' : '#000';
                }

                cell.style.backgroundColor = shouldUnderline ? '#EC5555' : '#FFF';
                cell.style.color = shouldUnderline ? '#FFF' : '#000';
            }
        }
    }
}

export function isValidInput(unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    const { calibrationValue } = calibrationInfo;

    const unit_id = unit.unit.unit_id

    if (!unit_id || !calibrationValue || isTwoDimensionalArrayEmpty(calibrationValue)) {
        calibrationInfo.calibrationConfirmPopoverError = 'Не все обязательные поля заполнены';
        return false;
    }

    return true;
}

export function hasInvalidFilterLength(calibrationFilter: string, unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]


    if (calibrationFilter.length >= 256) {
        calibrationInfo.calibrationConfirmPopoverError = 'Степень фильтрации - слишком большой текст';
        return true;
    }

    return false;
}

export function hasIncompleteLastRow(calibrationValue: calibrationValues[], unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    if (calibrationValue[calibrationValue.length - 1].length !== 0) {
        calibrationInfo.calibrationConfirmPopoverError = 'Заполните все поля таблицы до конца';
        return true;
    }

    return false;
}

export function hasNonZeroValues(calibrationValue: calibrationValues[]) {
    return calibrationValue.some(row => row.some(element => element !== 0));
}

export function hasInsufficientRows(calibrationValue: calibrationValues[], unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]


    if (calibrationValue.length < 2) {
        calibrationInfo.calibrationConfirmPopoverError = 'Должно быть заполнено хотя бы 2 поля';
        return true;
    }

    return false;
}

export function hasNullValues(calibrationValue: calibrationValues[]) {
    return calibrationValue.flat().some(element => element === null);
}

export function hasInvalidRows(calibrationValue: calibrationValues[], unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]


    if (calibrationValue.some(item => item.length < 2)) {
        calibrationInfo.calibrationConfirmPopoverError = 'Заполните все поля таблицы';
        return true;
    }

    return false;
}

export function hasNegativeNumbers(calibrationValue: calibrationValues[]) {
    return calibrationValue.some(item => item[0] < 0 || item[1] < 0);
}

export function validateOptionalField(filtration: string | null, dut_serial_number: number | null) {
    if (!filtration && !dut_serial_number) {
        return false
    }

    return true
}
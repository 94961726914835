import {TimerData} from "@/shared/api";
import {HeaderAxios, ResponseAxios} from "@/shared/api";

let axios = require('axios')

interface IProps {
    installer_id: number,
    task_id: number,
}

export const startTimer = (data: IProps) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`;

    return axios({
        method: 'POST',
        data: data,
        url: `${process.env.VUE_APP_DOCKER_HREF}/fitter/timer-task`,
        withCredentials: true,
        headers
    })
        .then((resp: any) => {
            if (resp.status === 200 || resp.status === 201) {
                return resp.data as TimerData
            } else {
                return resp.data
            }
        })
        .catch((err: any) => {
            return err.response
        })

}

import {FieldRowType} from "@/components/tracker/model/interface";
import {getObjectCountInTable} from "@/components/tracker/model/core/objects";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import * as coreService from '@/components/tracker/model/core'


export async function updateTask(api: any) {
    console.log('updateTask')
    if (!api.widgetStatusActive) {
        api.loading.widgetLoading = true
    }

    if (!api.CAN_UPDATE_TASK) return

    api.activate()
    api.task = api.getTask();

    interface IObjectKeys {
        [key: string]: string;
    }

    interface IObjectKeysArr {
        [key: string]: string[];
    }

    const form = (api.FORMS as IObjectKeys)[api.task.form_name];
    const departments = (api.ACCESS_DEPARTMENT as IObjectKeysArr)[form]

    if (api.myDepartments && api.myDepartments.length > 0) {
        const intersection = departments?.filter(element => api.myDepartments?.includes(element.toLowerCase()));

        api.IS_ACCESS = !!intersection.length;
    }

    if (!api.IS_ACCESS) {
        api.isFitter && (api.loading.cabinetLoading = true)

        return
    }

    const res = await CatalogInteraction.getDataFromTable(api)
    if (res) {
        getObjectCountInTable(api, res);
        (api.unitsData.allUnitsInTable as FieldRowType[]) = res
    }

    await api.getCabinetFromTask()

    await CatalogInteraction.getCatalogValueOnMount(api)

    api.getCalibrationInfo()

    api.loading.widgetLoading = false

    api.select.selectTableActive = "Пусто"
}


export async function updateTaskOnly(api: any, tableChange: boolean) {
    console.log('updateTaskOnly')

    if (api.NAME_IS_CHANGE_UPDATE_TASK) {
        return
    }

    if (api.isFitter && !api.IS_ACCESS) {
        api.loading.cabinetLoading = true;
        return;
    }

    if (!api.CAN_UPDATE_TASK) return

    if (!api.isFitter) {
        if (!api.IS_ACCESS) return

        if (api.notUpdateTaskWhenChangeAcceptUser) {
            api.notUpdateTaskWhenChangeAcceptUser = false

            return
        }
    }

    api.task = api.getTask();

    const oldLogin = api.userData.login
    const newLogin = await CatalogInteraction.getLoginValueFromTask(api)

    const oldSystem = api.userData.host_fullName
    const newSystem = await CatalogInteraction.getHostValueFromTask(api.task)

    if (oldLogin !== newLogin && oldLogin !== '' || oldSystem !== newSystem && oldSystem !== '') {
        return api.getCabinetFromTask()
    }


    if (api.createByHandObject.userImeiOrSerial && !api.select.createInputSensors) {
        await api.findCreatedByHandElementIMIE()
    }

    await coreService.getUserData(api, tableChange)

    api.getCalibrationInfo()
    api.updateCalibrationBySocket()

    CatalogInteraction.getCarInfoFromTable(api)
}
import {calibrationType, unit} from "@/components/tracker/model/interface";
import VueApexCharts from "vue-apexcharts";


export function renderLineChart(unit: calibrationType) {
    console.log('redner line chart')
    if (!unit.calibrationInfo.calibrationValue || unit.calibrationInfo.calibrationValue.length === 0) return

    const chartData = unit.calibrationInfo.calibrationValue.map(item => item.map(Number))

    const firstValues = chartData.map(pair => {
        if (pair && pair[0] !== null && pair[0] !== undefined) {
            return pair[0]
        }
    }).filter(value => value !== undefined);

    const secondValues = chartData.map(pair => {
        if (pair && pair[1] !== null || pair[1] !== undefined) {
            return pair[1]
        }
    }).filter(value => value !== undefined);

    unit.calibrationChartSeries = [{
        name: "Значение",
        data: firstValues as unknown as number[]
    }]


    unit.calibrationChartOption = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: '',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            type: 'numeric',
            tickAmount: secondValues.length,  // Number of ticks on the X-axis
            tickInterval: secondValues.length - 1,  // Number of ticks on the X-axis
            categories: secondValues,
            labels: {
                formatter: function (val: number) {
                    return val.toFixed(0);  // Отображать целые числа
                }
            }
        },
        colors: ['#009BF1'],
    }
}

export function updateChart(api: any, unit: unit) {
    console.log('update char')
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    if (!('calibrationChartOption' in calibrationArr[activeCalibrationIndex]) || !('calibrationChartSeries' in calibrationArr[activeCalibrationIndex])) {
        renderLineChart(calibrationArr[activeCalibrationIndex])
    }


    const {calibrationInfo, calibrationChartOption, calibrationChartSeries} = calibrationArr[activeCalibrationIndex]


    if (!calibrationInfo.calibrationValue || calibrationInfo.calibrationValue.length === 0) return

    const chartData = calibrationInfo.calibrationValue

    const firstValues = chartData.filter(value => value !== undefined).map(pair => {
        if (pair && pair[0] !== null && pair[0] !== undefined) {
            return pair[0]
        }
    }).filter(value => value !== undefined) as number[]

    const secondValues = chartData.map(pair => {
        if (pair && pair[1] !== null || pair[1] !== undefined) {
            return pair[1]
        }
    }).filter(value => value !== undefined) as number[];

    // Reference to the chart component
    const canvasArr = api.$refs[`chartCanvas_${unit.tracker.tracker_serial}`] as HTMLElement[]
    if (!canvasArr || canvasArr.length === 0) return

    const chart = canvasArr[0] as unknown as typeof VueApexCharts


    function alignArrays(arr1: number[], arr2: number[]) {
        const maxLength = Math.max(arr1.length, arr2.length);

        arr1 = arr1.concat(Array(maxLength - arr1.length).fill(0));
        arr2 = arr2.concat(Array(maxLength - arr2.length).fill(0));

        return {arr1, arr2};
    }

    const { arr1, arr2 } = alignArrays(firstValues, secondValues);

    // Создаем новые объекты с обновленными данными с использованием spread
    const updatedXAxis = { ...calibrationArr[activeCalibrationIndex].calibrationChartOption.xaxis, categories: [...arr2] };
    const updatedSeriesData = [...arr1];

    // Обновляем данные
    calibrationArr[activeCalibrationIndex].calibrationChartOption = {
        ...calibrationChartOption,
        xaxis: updatedXAxis,
    };

    calibrationArr[activeCalibrationIndex].calibrationChartSeries = [
        {
            ...calibrationArr[activeCalibrationIndex].calibrationChartSeries[0],
            data: updatedSeriesData,
        }
    ];
}
import {CalibrationBody, CalibrationEditBody, unit} from "@/components/tracker/model/interface";
import {APIFitter} from "@/components/tracker/model/api/fitter";
import {removeObjectFromArrayInLocalStorage} from "@/components/tracker/model/storage";


export async function handleCalibrationSubmit(api: any, unit: unit, index: number, data: CalibrationBody, calibrationIndex: number) {
    return await handleCalibrationOperation(api, unit, index, data, calibrationIndex, false);
}


export async function handleCalibrationEdit(api: any, unit: unit, index: number, data: CalibrationEditBody, calibrationIndex: number) {
    await handleCalibrationOperation(api, unit, index, data, calibrationIndex, true);
}

export async function handleCalibrationOperation(
    api: any,
    unit: unit,
    index: number,
    data: CalibrationBody | CalibrationEditBody,
    calibrationIndex: number,
    isEdit: boolean
): Promise<boolean | undefined> {
    const { calibrationArr, activeCalibrationIndex } = unit;

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return;

    const { calibrationInfo } = calibrationArr[activeCalibrationIndex];

    calibrationInfo.calibrationSubmitLoading = true;

    try {
        let res;

        if (isEdit) {
            res = await APIFitter.editCalibrationFields(api, data as CalibrationEditBody);
        } else {
            res = await APIFitter.createCalibration(api, data as CalibrationBody);
        }

        if (res.status === 200 && res.body.result === 'OK') {
            api.calibrationSaveNotification = true;
            window.navigator?.vibrate?.([50, 200]);

            setTimeout(() => {
                api.calibrationSaveNotification = false;
            }, 3000);

            handleCalibrationSubmitSuccess(api, unit, index, calibrationIndex);

            return true
        }

        handleCalibrationSubmitError(api, unit, res.status, res.body);
    } catch (error) {
        console.log(error);
        handleCalibrationSubmitError(api, unit);

        return
    } finally {
        calibrationInfo.calibrationSubmitLoading = false;
    }
}



function handleCalibrationSubmitSuccess(api: any, unit: unit, index: number, calibrationIndex: number) {
    const parent = api.unitsData.inGsAndInTable[index];

    if (parent) {
        parent.calibrationArr[calibrationIndex].calibrationInfo.calibrationValue.push([])
        parent.calibrationArr[calibrationIndex].calibrationInfo.calibrationConfirmPopover = false;

        // parent.calibrationArr[calibrationIndex].calibration = parent.calibrationArr[calibrationIndex].calibrationInfo.calibrationValue;

        setTimeout(() => {
            removeObjectFromArrayInLocalStorage(api.CALIBRATION_KEY, parent.unit.unit_id.toString(), calibrationIndex);
        }, 1000)
        //
        // api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable));
    }
}


interface errorMessage {
    message?: {
        size?: string[]
    }
}

function handleCalibrationSubmitError(api: any, unit: unit, status = 0, errorMessage: errorMessage = {}) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    if (status === 400) {
        if (errorMessage && errorMessage.message && errorMessage.message.size && errorMessage.message.size[0]) {
            if (errorMessage.message.size[0] === "Missing data for required field.") {
                calibrationInfo.calibrationConfirmPopoverError = 'Не все обязательные поля заполнены';

                return
            }
        }
        calibrationInfo.calibrationConfirmPopoverError = 'Произошла ошибка';
    } else {
        calibrationInfo.calibrationConfirmPopoverError = 'Произошла ошибка';
    }
}

import { defineComponent, PropType, ref } from "vue";
import GoogleMap from "@/components/tracker/GoogleMap.vue";
import Preloader from "@/shared/ui/preloader";
import QrReader from "@/components/code-reader/QrReader.vue";
import BarcodeReader from "@/components/code-reader/BarcodeReader.vue";
import VueApexCharts from 'vue-apexcharts';
import * as calibrationService from './tracker/model/calibration'
import * as coreService from './tracker/model/core'
import * as trackerMethods from './tracker/model/methods'
import * as commonMethods from './tracker/model/common'
import * as fitterMethod from './tracker/model/fitter'

import {
	CabinetData,
	CabinetDataSelect,
	calibrationValues,
	Catalog,
	CatalogDataTypeBody, CreateByHandObject,
	CreateUnitsType, IChangeSim, ISimData,
	Sensor,
	TaskDataType,
	TaskStatus, TrackerMany,
	unit,
	User, widgetStatusMenuObject,
} from "@/components/tracker/model/interface";
import {
	CALIBRATION_KEY,
	calibrationFindValue,
	calibrationFindValueMultiple,
	Check_Form,
	CR_Form,
	Delivery_Form,
	FITTER_TOKEN,
	HOST_MAP,
	MR_Form,
	ObjectsNameType,
	Old_Form,
	TO_Form, UserCabinetStatus,
} from "@/components/tracker/model/const";
import { haveCommonElements, isTwoDimensionalArrayEmpty, TimestampMethods } from "@/components/tracker/model/helpers";
import VNotification from "@/shared/ui/notification";
import { notificationStatus } from "@/utils/const";
import { UtilsWidget } from "@/components/tracker/model/utils";
import Popover from '@/components/tracker/ui/shared/popover/popover.vue'
import CreateSensor from "@/components/tracker/ui/shared/unit-select-sensor/create-sensor.vue";
import CreateSensorValidate from "@/components/tracker/ui/shared/unit-select-sensor/create-sensor-validate.vue";
import { getSensorForChangeTracker } from "@/components/tracker/model/methods/getSensorChange";
import { getAvailableSensor } from "@/components/tracker/model/core/objects/sensorObject";
import CabinetStatus from "@/components/tracker/ui/entities/cabinetStatus.vue";
import YandexMap from "@/components/tracker/YandexMap.vue";
import { APIFitter } from "@/components/tracker/model/api/fitter";
import SimLock from "@/components/tracker/ui/entities/simLock.vue";
import { disableSim } from "@/components/tracker/model/methods/sim_lock/disableSim";
import { enableSim } from "@/components/tracker/model/methods/sim_lock/enableSim";
import { changeSim } from "@/components/tracker/model/methods/sim_lock/changeSim";


export default defineComponent({
	name: 'TrackerWidget',
	components: {
		SimLock,
		YandexMap,
		CabinetStatus,
		CreateSensorValidate,
		CreateSensor,
		BarcodeReader,
		VNotification,
		QrReader,
		Preloader,
		GoogleMap,
		apexchart: VueApexCharts,
		Popover,
	},
	data() {
		return {
			isQrcode: false,
			isBarcode: false,
			CAN_UPDATE_TASK: true,
			task: {} as TaskDataType,
			type: notificationStatus,
			FITTER_DOMAIN: '',
			RADAR_DOMAIN: '',
			RADAR_TOKEN: this.radarTokenProps,
			RADAR_S3: 'https://task-radar.tspb.su',
			FITTER_TOKEN: FITTER_TOKEN,
			HOST_MAP: HOST_MAP,
			FORMS: {
				'МР': 'MR',
				'МР Новый': 'MR',
				'МР тест': 'MR',
				'СР': 'CR',
				'СР новый': 'CR',
				'СР тест': 'CR',
				'Выдача': 'DELIVERY',
				'Доставка': 'DELIVERY',
				"Ежедневный отчет": 'TO',
				"ЕО": 'TO',
				"ПЗ": 'TO',
				"ПХД": 'TO',
				"Инвентаризация": 'TO',
				"ИНВ": 'TO',
				"ТО": 'TO',
				'Проверка Трекера': 'CHECK',
				"МРпайрус": 'OLD_FORM',
				"ПЗпайрус": 'OLD_FORM',
				"СРпайрус": 'OLD_FORM',
				"Установка rus пайрус": 'OLD_FORM',
			},
			ACCESS_DEPARTMENT: {
				MR: MR_Form,
				CR: CR_Form,
				DELIVERY: Delivery_Form,
				OLD_FORM: Old_Form,
				TO: TO_Form,
				CHECK: Check_Form
			},
			IS_ACCESS: false,
            NAME_IS_CHANGE_UPDATE_TASK: false,
			calibrationNotificationSaveCount: '',
			calibrationStartSaveNotification: false,
			formWithoutTable: ["Проверка Трекера"] as string[],
			formWithoutUser: ["Ежедневный отчет", "ЕО", "ПЗ", "ПХД", "Инвентаризация", "ИНВ", "ТО"] as string[],
			formSR: ["СР", "СР новый", "СР тест"] as string[],
			formMR: ["МР", "МР Новый", "МР тест"] as string[],
			formWatchOnly: ['МРпайрус', "ПЗпайрус", "СРпайрус", "Установка rus пайрус"] as string[],
			formDelivery: ['Выдача', "Доставка"] as string[],
			eo_login: '25416',
			decode_address: '',
			calibrationFindValue: calibrationFindValue,
			calibrationFindValueMultiple: calibrationFindValueMultiple,
			calibrationSaveNotification: false,
			catalogs: {} as { [key: string]: CatalogDataTypeBody },
			allCatalogs: [] as Catalog[],
			debounceTimer: null as unknown as number,
			multipleCabinets: {
				allCabinets: [] as CabinetData[],
				selectedCabinets: null,
				cabinets: {} as CabinetData,
				allCabinetsSelect: [] as CabinetDataSelect[]
			},
			requestUpdateStatusBySocket: false,
			createCabinetByLogin: {
				errorMessage: '',
			},
			createCabinetBeGenerate: {
				errorMessage: '',
			},
			widgetStatus: {
				'generateLogin': 'generateLogin',
				'createByLogin': 'createByLogin',
				'cabinetNotFound': 'cabinetNotFound',
				'getCabinetObject': 'getCabinetObject',
			},
			select: {
				selectCabinetVisible: false,
				selectCabinetLabel: '',

				// Для выбора объекта из таблицы
				selectTableObjectOpen: false,
				selectTableActive: 'Пусто',
				selectTableTabindex: 0,

				// Для выбора кабинета
				selectCabinetOpen: false,
				selectCabinetActive: 'Выберите кабинет',
				selectCabinetIndex: 0,

				createTable: false,
				createInput: false,
				createInputSensors: false,

				notAllConditionsAreMet: false
			},
			loading: {
				cabinetLoading: false,
				widgetLoading: false,
				createObjectLoading: false,
				createObjectByHandLoading: false
			},
			statusSimObj: {
				active: {
					status: 'Активно',
					buttonText: 'Обновить',
					isLoading: 0,
					styleObject: {
						color: '#00BB34',
						backgroundColor: '#EDF8F0'
					}
				},
				loading: {
					status: 'Загрузка',
					buttonText: 'Активировать',
					isLoading: 1,
					styleObject: {
						color: '#E8A117',
						backgroundColor: '#FFF7E7'
					}
				},
				error: {
					status: 'Ошибка',
					buttonText: 'Активировать',
					isLoading: 0,
					styleObject: {
						color: '#EC5555',
						backgroundColor: '#FFE7E8'
					}
				},
				unknown: {
					status: 'Неизвестен',
					buttonText: 'Активировать',
					isLoading: 0,
					styleObject: {
						color: '#999999',
						backgroundColor: '#E7E8EA'
					}
				},
				blocked: {
					status: 'Заблокирована',
					buttonText: 'Активировать',
					isLoading: 0,
					styleObject: {
						color: '#EC5555',
						backgroundColor: '#FFE7E8'
					}
				},
				store: {
					status: 'Неактивна',
					buttonText: 'Активировать',
					isLoading: 0,
					styleObject: {
						color: '#999999',
						backgroundColor: '#E7E8EA'
					}
				}
			},
			createCabinetWithoutLogin: {
				clientClassCatalog: {
					clientClassCatalogIsOpen: false,
					clientClassCatalogType: [],
					clientClassTabIndex: 0,
				},
				loginMethodCatalog: {
					loginMethodCatalogIsOpen: false,
					loginMethodCatalogType: [],
					loginMethodCatalogIndex: 0
				},
				systemCatalog: {
					systemCatalogIsOpen: false,
					systemCatalogType: [],
					systemCatalogIndex: 0
				},
				regionCatalog: {
					regionCatalogIsOpen: false,
					regionCatalogType: [],
					regionCatalogIndex: 0
				}
			},
			widgetStatusActive: null as unknown as string,
			widgetStatusMenuObject: {
				popoverSelectChangeType: false,
				popoverChangeTracker: false,
				popoverEditSensor: false,

				isObjectWidgetShow: false,
				activeObjectWidget: 'Датчики',
				objectNotExistError: '',
				isMap: false,
				simQueryStatus: '',
				simIsActiveClick: false,
				simIsLoading: false,
				widgetStatus: {
					'sensor': 'Датчики',
					'command': 'Команды',
					'check': 'Проверка',
					'calibration': 'Тарировка',
                    'sim': 'СИМ карта'
				},
				buttonStatus: {
					disabled: false,
					statusUnderReview: false,

					disabledTO: false,
					disabledFitter: false,

					statusUnderReviewFitter: false,
					statusUnderReviewTO: false,

					fitterAccept: null,
					toAccept: null,
				},
				inputStatus: {
					isNameUnitActive: false,
					nameUnitActiveName: '',
					isNameChanged: false,

					isMileageUnitActive: false,
					mileageUniActiveName: 0,
					isMileageChanged: false
				},
				changeObject: {
					isQrCode: false,
					isBarCode: false,
					serialNumber: '',
					changeObjectLoading: false,
					changeObjectErrorString: '',
					changeObjectError: false,
					sensorBefore: [] as string[],
				},
			} as widgetStatusMenuObject,
			userData: {
				isTO: false,
				host: '',
				host_fullName: '',
				user_id: '',
				login: '',
				region: '',
				region_id: '',
				client_class: '',
				blockStatus: '' as UserCabinetStatus,
				login_method: '',
				login_method_value: '',
				contact: '',
				phone: ''
			},
			unitsData: {
				inGsAndInTable: [] as unit[],
				inGsOnly: [] as string[],
				inTableOnly: [] as string[],
				inTableOnlyIMEI: [] as string[],
				allUnitsInTable: [],
				allUnitsInTableCount: 0,
				resultUnits: [],
				unit_id: [] as number[]
			},
			timer: 0,
			isLoadingUnit: true,
			unitsName: {
				'I-Button': 'ibutton',
				'CAN-модуль': 'can',
				'Температура': 'temperature',
				'BLE Температура': 'temperaturebluetooth',
				'Реле блокировки': 'block',
				'ДУТ': 'fuel',
				'BLE ДУТ': 'fuel_ble',
				'2ДУТ': 'fuel_multiply',
				'ШДУТ': 'fuel_standart',
				'2ШДУТ': 'fuel_standart2',
				'Геркон': 'gerkon',
                'Прикур': 'prikur'
			} as { [key: string]: string },
			sensor: [] as Sensor[],
			createUnitData: {
				host: "",
				sensor: [] as Sensor[],
				task_id: 0,
				tracker: {
					number: "",
					unit_name: ""
				},
			},
			createUnitDataArr: [] as CreateUnitsType[],
			createUnitDataImeiOnly: [] as string[],
			createByHandObject: {
				objectInfo: {
					availableObject: [] as string[],
					isFindError: false,
					findErrorMessage: "Ошибка при создании",
					isCreateError: false,
					createErrorMessage: "Ошибка при создании",
					notCreated: false,
					sensorId: [] as string[],
					isLoading: false,
				},
				tracker_name: '',
				nameObject: '',
				imei: '',
				serial: '',
				userImeiOrSerial: '',
				ibutton: '',
				can: '',
				temperature: '',
				temperaturebluetooth: '',
				fuel: '',
				fuel_ble: '',
				fuel_multiply: '',
				fuel_standart: '',
				fuel_standart2: '',
				block: '',
				gerkon: '',
                prikur: '',
			} as CreateByHandObject,
			abortController: new AbortController(),
			isAborted: false,
			isRequestShouldCancelled: false,
			simChangeStatus: false,
			simChangeStatusType: notificationStatus.success,
			simChangeStatusText: '',
			simToggleStatus: false,
            simToggleNumber: false,
		}
	},
	setup() {
		return {
			cabinetError: ref(""),
			cabinet: {} as CabinetData,
			CALIBRATION_KEY,
			ObjectsNameType,
			TimestampMethods,
			haveCommonElements,
			isTwoDimensionalArrayEmpty,
			calibrationService,
			fitterMethod
		}
	},
	props: {
		getTask: {
			type: Function,
			required: true
		},
		changeTask: {
			type: Function,
			required: true
		},
		getRequest: {
			type: Function,
			required: true
		},
		postRequest: {
			type: Function,
			required: true
		},
		patchRequest: {
			type: Function,
			required: true
		},
		activate: {
			type: Function,
			required: true
		},
		active: {
			type: Boolean,
			required: true
		},
		isWidgetButtonActive: {
			type: Boolean,
			required: true
		},
		changeTable: {
			type: Function,
			required: true
		},
		isFitter: {
			type: Boolean,
			default: false
		},
		isTaskClosed: {
			type: Boolean,
			default: false
		},
		myDepartments: {
			type: Array as PropType<string[]>
		},
		radarTokenProps: {
			type: String
		},
		userId: {
			type: String
		},
		taskStatusProps: {
			type: Object as PropType<TaskStatus>
		},
		allUsers: {
			type: Array as PropType<User[]>,
			required: true
		}
	},
	emits: ['closeWidgetStatus'],
	async mounted() {
		this.$store.dispatch('changeActiveTab', 'tracker')

		if (window.location.hostname === 'localhost' || window.location.hostname === 'ofc-test-01.tspb.su') {
			this.FITTER_DOMAIN = 'https://ofc-test-01.tspb.su'
			this.RADAR_DOMAIN = 'https://task-radar.tspb.su'
		} else {
			this.FITTER_DOMAIN = 'https://fitter.tspb.su'
			this.RADAR_DOMAIN = 'https://api.rulse.ru'
		}

		this.userData.isTO = Boolean(this.myDepartments && this.myDepartments.includes("технический отдел"))

		if (this.isFitter) {

			this.timer = setInterval(() => {
				this.unitsData.unit_id = this.unitsData.inGsAndInTable.map((item: any) => {
					return item.unit.unit_id
				})

				this.callFuncToGetManyStatus(this.unitsData.unit_id)
			}, 30000)

			await this.updateTask()
		}
	},
	destroyed() {
		clearInterval(this.timer)
	},
	methods: {
		abort() {
			if (!this.isRequestShouldCancelled) return;
			this.abortController.abort()
			this.abortController = new AbortController()
		},
		// Запускается при открытии виджета
		async updateTask() {
			await coreService.updateTask(this)
		},
		async updateTaskOnly(tableChange: boolean) {
			await coreService.updateTaskOnly(this, tableChange)
		},

		////////////////////////////////// МЕТОДЫ ВЕРХНЕГО УРОВНЯ
		async getUserData(status: boolean) {
			await coreService.getUserData(this, status)
		},
		// Смотрит есть ли логин у пользователя
		// Если нет то предлагает создать кабинет
		// Если по логину нашлось несколько кабинетов то предлагает выбор
		async getCabinetFromTask() {
			await trackerMethods.getCabinetFromTask(this)
		},
		// Нажатие на кнопку создания кабинета по логину
		async createCabinetByLoginHandler() {
			await trackerMethods.createCabinetByLoginHandler(this)
		},
		// Нажатие на кнопку создания кабинета с логином который генерируется
		async generateCabinetHandler() {
			await trackerMethods.getCabinetFromTask(this)
		},
		// Создание кабинета по логину
		async createCabinetUsingLogin() {
			await trackerMethods.createCabinetUsingLogin(this)
		},
		// Создание кабинета с генерацией логина
		async createCabinetUsingGenerator() {
			await trackerMethods.createCabinetUsingGenerator(this)
		},
		// Получает объекты в кабинете
		async getUserObjects() {
			await coreService.getUserObjects(this)
		},

		async callFuncToGetManyStatus(unit_id: number[]) {
			await coreService.callFuncToGetManyStatus(this, unit_id)
		},

		sendObjectWorkToReview(type: string, userType: string, trackerSerial: string, trackerImei: string) {
			coreService.sendObjectWorkToReview(this, type, userType, trackerSerial, trackerImei)
		},

		// При нажатии на input делает его активным
		setInputActive(status: string, tracker_imei: string) {
			fitterMethod.InputInteraction.setInputActive(this, status, tracker_imei)
		},

		// Получает информацию об объекте по IMEI
		async findCreatedByHandElementIMIE() {
			await coreService.findCreatedByHandElementIMIE(this)
		},

		openOrCloseObjectInfo(tracker_imei: string) {
			fitterMethod.changeDOMElement.openOrCloseObjectInfo(this, tracker_imei)
		},

		changeTabInObjectInfo(tracker_imei: string, check: string) {
			fitterMethod.changeDOMElement.changeTabInObjectInfo(this, tracker_imei, check)
		},

		async showMap(tracker_imei: string, geo: TrackerMany) {
			fitterMethod.changeDOMElement.showMap(this, tracker_imei)

			if (!this.decode_address) {
				const body = {
					x: geo.geo_x,
					y: geo.geo_y
				}
				const res = await APIFitter.decodeGeoCode(this, body);

				if (res && res.status === 200) {
					this.decode_address = res.body.data
				}
			}
		},

		// Создание объекта вручную
		async createUnitByHand() {
			await coreService.createUnitByHand(this)
		},

		changeUnitByHand(unit: unit) {
			coreService.changeUnitByHand(this, unit)
		},

		changeUnitSensorByHand(unit: unit) {
			coreService.changeUnitSensorByHand(this, unit)
		},

		clearCreateByHandObject() {
			UtilsWidget.clearCreateByHandObjectFunc(this)
		},

		closeCreateByHandModal() {
			UtilsWidget.closeCreateByHandModal(this)
		},

		lastTrackerMess(time: number) {
			return UtilsWidget.lastTrackerMess(time)
		},


		handleQr() {
			fitterMethod.qrBarManager.handleQr(this)
		},

		handleBar() {
			fitterMethod.qrBarManager.handleBar(this)
		},

		handleQrChange(unit: unit) {
			fitterMethod.qrBarManager.handleQrChange(this, unit)
		},

		handleBarChange(unit: unit) {
			fitterMethod.qrBarManager.handleBarChange(this, unit)
		},


		decodeQr(data: string) {
			fitterMethod.qrBarManager.decodeQr(this, data)
		},

		decodeBar(data: string) {
			fitterMethod.qrBarManager.decodeBar(this, data)
		},

		decodeQrChange(data: string) {
			fitterMethod.qrBarManager.decodeQrChange(this, data)
		},

		decodeBarChange(data: string) {
			fitterMethod.qrBarManager.decodeBarChange(this, data)
		},


		closeInputActive(status: string, tracker_imei: string) {
			fitterMethod.changeDOMElement.closeInputActive(this, status, tracker_imei)
		},

		// При блюре изменяет значение у объекта
		blurInputActive(status: string, tracker_imei: string) {
			commonMethods.ChangeDOMElementCommon.blurInputActive(this, status, tracker_imei)
		},

		async executeCommand(command_name: string, unit_id: string, unit_name: string, index: number) {
			await trackerMethods.Command.executeCommand(this, command_name, unit_id, unit_name, index)
		},

		async activateSim(phone: number, index: number) {
			await trackerMethods.Sim.activateSim(this, phone, index)
		},


		// POPOVER
		setEditSensorPopoverActive(unit: unit, status: boolean) {
			getAvailableSensor(this)
			getSensorForChangeTracker(this, unit)
			fitterMethod.changeDOMElement.setEditSensorPopoverActive(this, unit, status)
		},

		setChangeTrackerPopoverActive(unit: unit, status: boolean) {
			fitterMethod.changeDOMElement.setChangeTrackerPopoverActive(this, unit, status)
		},

		closeEditSensorAndChangeTrackerPopover(unit: unit) {
			fitterMethod.changeDOMElement.closeEditSensorAndChangeTrackerPopover(this, unit)
		},

		selectChangePopover(unit: unit, status: boolean) {
			fitterMethod.changeDOMElement.selectChangePopover(this, unit, status)
		},

		getSelectChangePopover(unit: unit) {
			return () => fitterMethod.changeDOMElement.selectChangePopover(this, unit, false)
		},

		getClosedEditSensorAndChangeTrackerPopover(unit: unit) {
			return () => fitterMethod.changeDOMElement.closeEditSensorAndChangeTrackerPopover(this, unit)
		},

		closeQrConnection() {
			this.isQrcode = false
		},

		closeBarCodeConnection() {
			this.isBarcode = false
		},

		// ТАРИРОВКА

		getCalibrationInfo() {
			console.log('!!!!!!!!getCalibrationInfo')
			calibrationService.computeCalibration(this, this.task)
		},

		updateCalibrationBySocket() {
			calibrationService.updateCalibrationBySocket(this, this.task)
		},

		saveCalibration(unit: unit, index: number, calibrationIndex: number) {
			calibrationService.saveCalibrationValue(this, unit, index, calibrationIndex)
		},

		saveDoubleCalibration(unit: unit, index: number) {
			calibrationService.saveDoubleCalibration(this, unit, index)
		},

		changeCalibrationFieldValue(unit: unit, fieldId: number, cellId: number, value: number) {
			calibrationService.changeCalibrationFieldValue(this, unit, fieldId, cellId, value)
		},

		selectFileCalibration(tracker_serial: number) {
			calibrationService.selectFileCalibration(this, tracker_serial)
		},

		changeFileCalibration(event: Event, calibration: calibrationValues[], unit: unit, index: number) {
			calibrationService.changeFileCalibration(this, event, calibration, unit, index)
		},

		updateCalibrationValue(unit: unit) {
			calibrationService.updateLocalStorageWhenInput(this, unit, this.task)
		},

		swapCalibrationColumns(unit: unit) {
			calibrationService.swapColumnsValues(unit)

			calibrationService.openConfirmPopoverAndCloseSwapPopover(this, unit)
		},

        saveCalibrationSettings(unit: unit) {
            calibrationService.applyCalibrationSettings(this, unit)
            calibrationService.closeCalibrationSettingPopup(this, unit)
        },

		confirmCalibration(unit: unit, editOnlyText?: boolean, validateExistingOnServer: boolean = false) {
			if (validateExistingOnServer && unit.calibrationArr[unit.activeCalibrationIndex].calibrationInfo.calibrationExistOnServer) {
				this.changeAdditionCalibrationData(unit)

				return
			}

			calibrationService.confirmCalibration(this, unit, editOnlyText)
		},

		changeAdditionCalibrationData(unit: unit) {
			calibrationService.changeAdditionCalibrationData(this, unit)
		},

		clearCalibrationTable(unit: unit) {
			calibrationService.clearCalibrationTable(this, unit)
		},

		swapActiveCalibration(unit: unit, calibrationId: number) {
			unit.activeCalibrationIndex = calibrationId
		},

        insertCopyCalibration(unit: unit) {
            calibrationService.insertCopyCalibration(this, unit)
        },

		// POPOVER CALIBRATION

		openPopupBeforeClearCalibration(unit: unit) {
			calibrationService.openPopupBeforeClearCalibration(this, unit)
		},

		closeCalibrationPopover(unit: unit) {
			calibrationService.closeCalibrationPopover(this, unit)
		},

		closeCalibrationFilePopover(unit: unit) {
			calibrationService.closeCalibrationFilePopover(this, unit)
		},

		closeSwapCalibrationPopover(unit: unit) {
			calibrationService.closeSwapCalibrationPopover(this, unit)
		},

		closeClearCalibrationPopover(unit: unit) {
			calibrationService.closeClearCalibrationPopover(this, unit)
		},

		closeRewriteCalibrationPopover(unit: unit) {
			calibrationService.closeRewriteCalibrationPopover(this, unit)
		},

		openConfirmPopoverAndCloseSwapPopover(unit: unit) {
			calibrationService.openConfirmPopoverAndCloseSwapPopover(this, unit)
		},

        openCalibrationSettingPopup(unit: unit) {
            calibrationService.openCalibrationSettingPopup(this, unit)
        },

        closeCalibrationSettingPopup(unit: unit) {
            calibrationService.closeCalibrationSettingPopup(this, unit)
        },

		getSwapCloseFunction(unit: unit) {
			return () => calibrationService.closeSwapCalibrationPopover(this, unit)
		},

		getCloseFunction(unit: unit) {
			return () => calibrationService.closeCalibrationPopover(this, unit);
		},

		getCloseRewriteFunction(unit: unit) {
			return () => calibrationService.closeRewriteCalibrationPopover(this, unit)
		},

        getCloseSettingPopup(unit: unit) {
            return () => calibrationService.closeCalibrationSettingPopup(this, unit)
        },

		getClearCloseFunction(unit: unit) {
			return () => calibrationService.closeClearCalibrationPopover(this, unit)
		},

		getFileCloseFunction(unit: unit) {
			return () => calibrationService.closeCalibrationFilePopover(this, unit)
		},

		//SIM
		async disableSimHandler(data: ISimData) {
			if (this.simToggleStatus) return

			this.simToggleStatus = true
			await disableSim(this, data)
			await this.callFuncToGetManyStatus(this.unitsData.unit_id)

			this.simToggleStatus = false
		},

		async enableSimHandler(data: ISimData) {
			if (this.simToggleStatus) return

			this.simToggleStatus = true
			await enableSim(this, data)
			await this.callFuncToGetManyStatus(this.unitsData.unit_id)

			this.simToggleStatus = false
		},

		async changeSimHandler(data: IChangeSim) {
			if (this.simToggleNumber) return

            this.simToggleNumber = true

            this.simChangeStatusText = 'Началась смена номера'
            this.simChangeStatusType = this.type.warning
            this.simChangeStatus = true

			const responseCode = await changeSim(this, data);

			this.simChangeStatusType = this.type.success

			if (responseCode === 200) {
				this.simChangeStatusText = 'Номер SIM успешно обновлен'
			} else if (responseCode === 202) {
				this.simChangeStatusText = 'Запрос на изменение SIM отправлен'
			} else {
				this.simChangeStatusType = this.type.error
				this.simChangeStatusText = 'Ошибка при обновлении номера'
			}

			setTimeout(() => {
				this.simChangeStatus = false
			}, 2500)

			await this.callFuncToGetManyStatus(this.unitsData.unit_id)

            this.simToggleNumber = false
		},
	}
})
;
